import React from "react";
// import logo from '../../img/Logo.png';
import './Logo.css';

const Logo = () => {
    return (
        <div>
        {/* <div className="Top-Logo">
             <img src={logo} alt="logo"/>
         </div> */}
            <h1 className="company">Company Name</h1>        

        </div>
    )
}

export default Logo;